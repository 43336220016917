import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string, shape } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  txIsDiscussion,
  txIsProDiscussion,
  txIsCanceled,
  txIsDisputed,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsAccepted,
  txIsDeclined,
  txIsRequested,
  txIsProposal,
  txHasBeenDelivered,
  txHasBeenClosed,
  txHasBeenAccepted,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_ACCEPT_PROPOSAL,
  TRANSITION_COMPLETE_PROPOSAL,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import {
  averageProfessionalReview,
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
  isUserPro,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import {
  NamedLink,
  ReviewModal,
  DailyLogsModal,
  DailyLogsDetailsModal,
  UserDisplayName,
  PrimaryButton,
  SecondaryButton,
  BookingPanel,
  SubscriptionModal,
  IconArrowHead,
  IconMessage,
  IconLogs,
  IconWarning,
  IconReviewUser,
} from '../../components';
import { SendMessageForm } from '../../forms';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import DocumentLinkMaybe from './DocumentLinkMaybe';
import EnquiryBreakdownMaybe from './EnquiryBreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import PanelHeading, {
  HEADING_DISCUSSION,
  HEADING_PRO_DISCUSSION,
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_CLOSED_QUOTE,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_DISPUTED,
  HEADING_PROPOSAL,
} from './PanelHeading';

import SaleCompletedButtonMaybe from './SaleCompletedButtonMaybe';
import DiminishedActionButtonMaybe from './DiminishedActionButtonMaybe';
import SaleCounterOfferButtonMaybe from './SaleCounterOfferButtonMaybe';
import SaleCounterOfferActionButtonsMaybe from './SaleCounterOfferActionButtonsMaybe';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import SaleProposalButtonsMaybe from './SaleProposalButtonsMaybe';
import PageTabs from './PageTabs';
import DailyLogs from './DailyLogs';
import AdditionalInformations from './AdditionalInformations';

import css from './TransactionPanel.module.css';

const MOBILE_BREAKPOINT = 768;
const OLD_TRANSACTION_PROCESS_VERSION = 3;

const CHAT_TAB = 'chat';
const LOGS_TAB = 'logs';
const INFO_TAB = 'info';

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReviewModalOpen: false,
      isDailyLogsModalOpen: false,
      isDailyLogsDetailsModalOpen: false,
      dailyLogSubmitted: false,
      currentDailyLogId: null,
      sendMessageFormFocused: false,
      reviewSubmitted: false,
      showDocument: false,
      showSubscriptionModal: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole, onUpdateProfile } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const isProposalTransaction = currentTransaction.attributes.transitions
      .map(t => t.transition)
      ?.includes(TRANSITION_COMPLETE_PROPOSAL);
    const {
      reviewRating,
      reviewContent,
      communication,
      professionalism,
      punctuality,
      jobQuality,
      cleanliness,
    } = values;
    const professionalAttributes = {
      communication,
      professionalism,
      punctuality,
      jobQuality,
      cleanliness,
    };

    const rating = Number.parseInt(
      reviewRating ? reviewRating : averageProfessionalReview(professionalAttributes),
      10
    );

    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(reviewId => {
        const isProvider = isProposalTransaction
          ? transactionRole === 'customer'
          : transactionRole === 'provider';
        this.setState({ isReviewModalOpen: false, reviewSubmitted: true });

        if (isProvider) {
          const currentCustomer = ensureUser(
            isProposalTransaction ? currentTransaction.provider : currentTransaction.customer
          );
          const review = {
            id: reviewId.uuid,
            attributes: professionalAttributes,
          };

          onUpdateProfile(currentCustomer.id.uuid, review);
        }
      })
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      params,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onMarkCompleted,
      markCompletedInProgress,
      markCompletedError,
      originalListing,
      previousTransactionCreatedAt,
      transactionDocument,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      sendEnquiryInProgress,
      sendEnquiryError,
      onSendEnquiry,
      currentUserProfessionalListingId,
      onOpenDisputeModal,
      onOpenCounterOfferModal,
      acceptCounterOfferInProgress,
      declineCounterOfferInProgress,
      acceptCounterOfferError,
      declineCounterOfferError,
      onAcceptCounterOffer,
      onDeclineCounterOffer,
      oldQuoteAfterCounterOffer,
      customerListings,
      onAcceptProposal,
      acceptProposalInProgress,
      acceptProposalError,
      onDeclineProposal,
      declineProposalInProgress,
      declineProposalError,
      onCompleteProposal,
      completeProposalInProgress,
      completeProposalError,
      currentUserQuotes,
      history,
      location: locationObject,
      onCreateDailyLog,
      createDailyLogInProgress,
      createDailyLogError,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const dailyLogs = currentTransaction.attributes.metadata.dailyLogs;
    const isLogsTab = params.tab === LOGS_TAB;
    const isInfoTab = params.tab === INFO_TAB;
    const isChatTab = params.tab === CHAT_TAB || (!isLogsTab && !isInfoTab);

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const isCustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = isCustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = isCustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    const isProposalTransaction = currentTransaction.attributes.transitions
      .map(t => t.transition)
      ?.includes(TRANSITION_COMPLETE_PROPOSAL);

    const transactionId = params.id || currentTransaction.id.uuid;

    const sendEnquiry = values => {
      const { budget, document } = values;

      const professionalListingId = currentUserProfessionalListingId?.uuid;
      const transactionId = currentTransaction.id;

      const isCurrentUserPro = isUserPro(currentUser);
      const showSubscriptionModal = !isCurrentUserPro && currentUserQuotes >= 2;

      if (showSubscriptionModal) {
        this.setState({ isSubscriptionModalOpen: true });
      } else {
        onSendEnquiry({ professionalListingId, budget, document, transactionId });
      }
    };

    const stateDataFn = tx => {
      const isCounterOffer = tx.attributes.lastTransition === TRANSITION_COUNTER_OFFER;
      if (txIsDiscussion(tx) || txIsProDiscussion(tx)) {
        return {
          headingState: txIsDiscussion(tx) ? HEADING_DISCUSSION : HEADING_PRO_DISCUSSION,
          showBreakdown: false,
        };
      } else if (txIsProposal(tx)) {
        return {
          headingState: HEADING_PROPOSAL,
          showProposalButtons: isProvider,
          showBreakdown: false,
        };
      } else if (txIsEnquired(tx)) {
        return {
          headingState: HEADING_ENQUIRED,
          showSaleButtons: !isCounterOffer && isProvider,
          showCounterOfferButton: !isCounterOffer && isProvider,
          showCounterOfferActionButtons: isCounterOffer && isCustomer,
          showBreakdown: false,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: true,
          showBreakdown: true,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: true,
          showBreakdown: true,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: true,
          showBreakdown: true,
          showDispute: isProvider,
        };
      } else if (txIsAccepted(tx)) {
        const isProposalAccepted = tx.attributes.lastTransition === TRANSITION_ACCEPT_PROPOSAL;
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isProposalAccepted ? isProvider : isCustomer,
          showAddress: isProposalAccepted ? isProvider : isCustomer,
          showMarkCompletedButton: !isProposalAccepted && isProvider,
          showMarkCompletedProposalButton: isProposalAccepted && isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
          showBreakdown: false,
          showAcceptButton: false,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: true,
          showBreakdown: true,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: true,
          showAddress: isCustomer,
          showBreakdown: true,
        };
      } else if (txIsDisputed(tx)) {
        return {
          headingState: HEADING_DISPUTED,
          showDetailCardHeadings: isCustomer,
          showBreakdown: true,
        };
      } else if (txHasBeenClosed(tx)) {
        return {
          headingState: HEADING_CLOSED_QUOTE,
          showDetailCardHeadings: true,
          showBreakdown: false,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const bookingDates = publicData?.dates;
    const transactionQuote = transaction.attributes.protectedData.quote;

    const originalListingImage =
      originalListing?.images?.length > 0 ? originalListing.images[0] : null;
    const currentListingImage =
      currentListing?.images?.length > 0 ? currentListing.images[0] : null;
    const firstImage = originalListing ? originalListingImage : currentListingImage;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const proposalButtons = (
      <SaleProposalButtonsMaybe
        showButtons={stateData.showProposalButtons}
        acceptInProgress={acceptProposalInProgress}
        declineInProgress={declineProposalInProgress}
        acceptProposalError={acceptProposalError}
        declineProposalError={declineProposalError}
        onAcceptProposal={() => onAcceptProposal(currentTransaction.id)}
        onDeclineProposal={() => onDeclineProposal(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned &&
      !isCustomerDeleted &&
      !isProviderBanned &&
      !isProviderDeleted &&
      !txHasBeenClosed(currentTransaction);

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });
    const sendingMessageNotAllowedIsClosed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowedIsClosed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const counterOfferActionButtons = (
      <SaleCounterOfferActionButtonsMaybe
        showButtons={stateData.showCounterOfferActionButtons}
        acceptCounterOfferInProgress={acceptCounterOfferInProgress}
        declineCounterOfferInProgress={declineCounterOfferInProgress}
        acceptCounterOfferError={acceptCounterOfferError}
        declineCounterOfferError={declineCounterOfferError}
        onAcceptCounterOffer={() => onAcceptCounterOffer(currentTransaction.id)}
        onDeclineCounterOffer={() =>
          onDeclineCounterOffer(currentTransaction.id, oldQuoteAfterCounterOffer)
        }
      />
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MOBILE_BREAKPOINT;
    const classes = classNames(rootClassName || css.root, className);

    const isProDiscussion = txIsProDiscussion(currentTransaction);
    const showDailyLogs = txHasBeenAccepted(currentTransaction);

    const tabs = [
      {
        key: 'chatTabLink',
        text: intl.formatMessage({ id: 'TransactionPanel.chatLink' }),
        selected: isChatTab,
        notification: false,
        icon: <IconMessage className={css.tabIcon} />,
        linkProps: {
          name: isProvider ? 'SaleDetailsPage' : 'OrderDetailsPage',
          params: { id: transactionId, tab: 'chat' },
        },
      },
      {
        key: 'logsTabLink',
        text: intl.formatMessage({ id: 'TransactionPanel.dailyLogsLink' }),
        selected: isLogsTab,
        notification: false,
        icon: <IconLogs className={css.tabIcon} />,
        linkProps: {
          name: isProvider ? 'SaleDetailsPage' : 'OrderDetailsPage',
          params: { id: transactionId, tab: 'logs' },
        },
      },
      {
        key: 'infoTabLink',
        text: intl.formatMessage({ id: 'TransactionPanel.infosLink' }),
        selected: isInfoTab,
        notification: false,
        icon: <IconReviewUser className={css.tabIcon} />,
        linkProps: {
          name: isProvider ? 'SaleDetailsPage' : 'OrderDetailsPage',
          params: { id: transactionId, tab: 'info' },
        },
      },
    ];

    const chatContent = (
      <>
        <PanelHeading
          panelHeadingState={stateData.headingState}
          transaction={currentTransaction}
          transactionRole={transactionRole}
          providerName={authorDisplayName}
          customerName={customerDisplayName}
          isCustomerBanned={isCustomerBanned}
          listingId={currentListing.id && currentListing.id.uuid}
          listingTitle={listingTitle}
          listingDeleted={listingDeleted}
          currentProvider={currentProvider}
          originalListing={originalListing}
        />
        <div className={css.bookingDetailsMobile}>
          <DocumentLinkMaybe
            transactionDocument={transactionDocument}
            showDocument={this.state.showDocument}
            onShowDocument={() => this.setState({ showDocument: !this.state.showDocument })}
          />
          <AddressLinkMaybe
            rootClassName={css.addressMobile}
            location={location}
            geolocation={geolocation}
            showAddress={stateData.showAddress}
          />

          {isMobileLayout && txIsDiscussion(currentTransaction) && isCustomer ? (
            <BookingPanel
              className={css.bookingPanel}
              listing={currentListing}
              onSubmit={sendEnquiry}
              title={listingTitle}
              onManageDisableScrolling={onManageDisableScrolling}
              sendEnquiryInProgress={sendEnquiryInProgress}
              sendEnquiryError={sendEnquiryError}
              quoteFormId="OrderDetails.QuoteFormMobile"
            />
          ) : null}

          <EnquiryBreakdownMaybe
            intl={intl}
            userRole={transactionRole}
            transaction={transaction}
            transactionQuote={transactionQuote}
            bookingDates={bookingDates}
          />
          <DiminishedActionButtonMaybe
            showDispute={stateData.showDispute}
            onOpenDisputeModal={onOpenDisputeModal}
          />
        </div>
        {savePaymentMethodFailed ? (
          <p className={css.genericError}>
            <FormattedMessage
              id="TransactionPanel.savePaymentMethodFailed"
              values={{ paymentMethodsPageLink }}
            />
          </p>
        ) : null}
        <FeedSection
          rootClassName={css.feedContainer}
          currentTransaction={currentTransaction}
          currentUser={currentUser}
          fetchMessagesError={fetchMessagesError}
          fetchMessagesInProgress={fetchMessagesInProgress}
          initialMessageFailed={initialMessageFailed}
          messages={messages}
          oldestMessagePageFetched={oldestMessagePageFetched}
          onOpenReviewModal={this.onOpenReviewModal}
          onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
          totalMessagePages={totalMessagePages}
          previousTransactionCreatedAt={previousTransactionCreatedAt}
          originalListing={originalListing}
        />
        {showSendMessageForm ? (
          <SendMessageForm
            formId={this.sendMessageFormName}
            rootClassName={css.sendMessageForm}
            messagePlaceholder={sendMessagePlaceholder}
            inProgress={sendMessageInProgress}
            sendMessageError={sendMessageError}
            onFocus={this.onSendMessageFormFocus}
            onBlur={this.onSendMessageFormBlur}
            onSubmit={this.onMessageSubmit}
          />
        ) : txHasBeenClosed(currentTransaction) ? (
          <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowedIsClosed}</div>
        ) : (
          <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
        )}
      </>
    );

    const logsContent = showDailyLogs ? (
      <div className={css.dailyLogsContent}>
        <DailyLogs
          isCustomer={isCustomer}
          dailyLogs={dailyLogs}
          fetchDailyLogsInProgress={!currentTransaction.id}
          onOpenDailyLogsModal={() => this.setState({ isDailyLogsModalOpen: true })}
          onViewLog={logId => {
            this.setState({ currentDailyLogId: logId, isDailyLogsDetailsModalOpen: true });
          }}
        />
      </div>
    ) : (
      <div className={css.dailyLogsContent}>
        {isProvider ? (
          <p className={css.dailyLogsMessage}>
            <IconWarning className={css.dailyLogsMessageIcon} />
            <FormattedMessage id="TransactionPanel.providerInvalidDailyLogsMessage" />
          </p>
        ) : (
          <p className={css.dailyLogsMessage}>
            <IconWarning className={css.dailyLogsMessageIcon} />
            <FormattedMessage id="TransactionPanel.customerInvalidDailyLogsMessage" />
          </p>
        )}
      </div>
    );

    const homeAddress = currentListing?.attributes?.publicData?.homeAddress;
    const specialNotes = currentListing?.attributes?.publicData?.specialNotes;
    const infoContent = (
      <AdditionalInformations
        isTxAccepted={txHasBeenAccepted(currentTransaction)}
        isProvider={isProvider}
        homeAddress={homeAddress}
        specialNotes={specialNotes}
      />
    );

    const pageContentFn = () => {
      switch (params.tab) {
        case CHAT_TAB:
          return chatContent;
        case LOGS_TAB:
          return logsContent;
        case INFO_TAB:
          return infoContent;

        default:
          return chatContent;
      }
    };

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />

            <PageTabs tabs={tabs} />
            {pageContentFn()}

            {txIsEnquired(currentTransaction) ? (
              <div className={css.mobileActionsButtons}>
                <div className={classNames(css.actionButtons, css.mobilePaymentAndDeclineButtons)}>
                  {stateData.showCounterOfferButton ? (
                    <>
                      <div className={css.mobileSaleButtons}>
                        <SecondaryButton
                          inProgress={declineInProgress}
                          disabled={declineInProgress}
                          onClick={() => onDeclineSale(currentTransaction.id)}
                        >
                          <FormattedMessage id="TransactionPanel.declineButton" />
                        </SecondaryButton>
                        <PrimaryButton
                          inProgress={acceptInProgress}
                          disabled={acceptInProgress}
                          onClick={() => onAcceptSale(currentTransaction.id)}
                        >
                          <FormattedMessage id="TransactionPanel.acceptButton" />
                        </PrimaryButton>
                      </div>

                      <SecondaryButton
                        className={css.actionButton}
                        onClick={onOpenCounterOfferModal}
                      >
                        <FormattedMessage id="TransactionPanel.counterOffer" />
                      </SecondaryButton>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}

            {stateData.showProposalButtons ? (
              <div className={css.mobileActionButtons}>{proposalButtons}</div>
            ) : null}

            {stateData.showMarkCompletedButton ? (
              <div className={css.mobileActionsButtons}>
                <SaleCompletedButtonMaybe
                  showButton={stateData.showMarkCompletedButton}
                  markCompletedInProgress={markCompletedInProgress}
                  markCompletedError={markCompletedError}
                  onMarkCompleted={() => onMarkCompleted(currentTransaction.id)}
                />
              </div>
            ) : null}

            {stateData.showMarkCompletedProposalButton ? (
              <div className={css.mobileActionsButtons}>
                <SaleCompletedButtonMaybe
                  showButton={stateData.showMarkCompletedProposalButton}
                  markCompletedInProgress={completeProposalInProgress}
                  markCompletedError={completeProposalError}
                  onMarkCompleted={() => onCompleteProposal(currentTransaction.id)}
                />
              </div>
            ) : null}

            {stateData.showCounterOfferActionButtons ? (
              <div className={css.mobileActionsButtons}>{counterOfferActionButtons}</div>
            ) : null}
          </div>

          {!isProDiscussion ? (
            <div className={css.asideDesktop}>
              <div className={css.detailCard}>
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentProvider}
                  isCustomer={isCustomer}
                />

                <DetailCardHeadingsMaybe
                  showDetailCardHeadings={stateData.showDetailCardHeadings}
                  listingTitle={originalListing?.attributes?.title}
                  listingId={originalListing?.id?.uuid}
                />

                <DocumentLinkMaybe
                  transactionDocument={transactionDocument}
                  showDocument={this.state.showDocument}
                  onShowDocument={() => this.setState({ showDocument: !this.state.showDocument })}
                />

                {!isMobileLayout && txIsDiscussion(currentTransaction) && isCustomer ? (
                  <BookingPanel
                    className={css.bookingPanel}
                    listing={currentListing}
                    onSubmit={sendEnquiry}
                    title={listingTitle}
                    onManageDisableScrolling={onManageDisableScrolling}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    sendEnquiryError={sendEnquiryError}
                    quoteFormId="OrderDetails.QuoteForm"
                  />
                ) : null}

                {/* SHOW THE ENQUIRY BREAKDOWN */}
                <EnquiryBreakdownMaybe
                  intl={intl}
                  userRole={transactionRole}
                  transaction={transaction}
                  transactionQuote={transactionQuote}
                  bookingDates={bookingDates}
                />

                {stateData.showSaleButtons ? (
                  <div className={css.desktopActionButtons}>{saleButtons}</div>
                ) : null}

                {stateData.showProposalButtons ? (
                  <div className={css.desktopActionButtons}>{proposalButtons}</div>
                ) : null}

                {/* SHOW THE COUNTER OFFER BUTTON */}
                {stateData.showCounterOfferButton ? (
                  <div className={css.desktopActionsButtons}>
                    <SaleCounterOfferButtonMaybe
                      showButton={stateData.showCounterOfferButton}
                      onClick={onOpenCounterOfferModal}
                    />
                  </div>
                ) : null}

                {/* SHOW THE MARK COMPLETED BUTTON */}
                {stateData.showMarkCompletedButton ? (
                  <div
                    className={classNames(css.desktopActionsButtons, css.desktopCompletedButtons)}
                  >
                    <SaleCompletedButtonMaybe
                      showButton={stateData.showMarkCompletedButton}
                      markCompletedInProgress={markCompletedInProgress}
                      markCompletedError={markCompletedError}
                      onMarkCompleted={() => onMarkCompleted(currentTransaction.id)}
                    />
                  </div>
                ) : null}

                {/* SHOW THE MARK COMPLETED PROPOSAL BUTTON */}
                {stateData.showMarkCompletedProposalButton ? (
                  <div
                    className={classNames(css.desktopActionsButtons, css.desktopCompletedButtons)}
                  >
                    <SaleCompletedButtonMaybe
                      showButton={stateData.showMarkCompletedProposalButton}
                      markCompletedInProgress={completeProposalInProgress}
                      markCompletedError={completeProposalError}
                      onMarkCompleted={() => onCompleteProposal(currentTransaction.id)}
                    />
                  </div>
                ) : null}

                {stateData.showCounterOfferActionButtons ? (
                  <div className={css.desktopCounterOfferActionButtons}>
                    {counterOfferActionButtons}
                  </div>
                ) : null}

                <DiminishedActionButtonMaybe
                  showDispute={stateData.showDispute}
                  onOpenDisputeModal={onOpenDisputeModal}
                />
              </div>
            </div>
          ) : (
            isProvider && (
              <div className={css.asideDesktop}>
                <div className={css.detailInfoCard}>
                  <div className={css.detailInfoCardHeadings}>
                    <h2 className={css.detailInfoCardTitle}>
                      <FormattedMessage
                        id="TransactionPanel.detailInfoCardTitle"
                        values={{ displayName: customerDisplayName }}
                      />
                    </h2>
                    <ul className={css.detailInfoCardListings}>
                      {customerListings.map(l => {
                        const customerListingId = l.id.uuid;
                        const customerListingTitle = l.attributes.title;
                        return (
                          <li key={customerListingId} className={css.detailInfoCardListing}>
                            <NamedLink
                              name="ListingPage"
                              params={{ id: customerListingId, slug: customerListingTitle }}
                            >
                              {customerListingTitle}
                              <IconArrowHead className={css.arrow} direction="right" />
                            </NamedLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          isProvider={isProposalTransaction ? isCustomer : isProvider}
        />
        <DailyLogsModal
          id="DailyLogsModal"
          isOpen={this.state.isDailyLogsModalOpen}
          onCloseModal={() => this.setState({ isDailyLogsModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitLog={async values => {
            onCreateDailyLog(currentTransaction.id.uuid, values).then(() => {
              this.setState({ dailyLogSubmitted: true });

              setTimeout(() => {
                this.setState({ isDailyLogsModalOpen: false, dailyLogSubmitted: false });
              }, [1200]);
            });
          }}
          createLogInProgress={createDailyLogInProgress}
          createLogError={createDailyLogError}
          dailyLogSubmitted={this.state.dailyLogSubmitted}
        />
        <DailyLogsDetailsModal
          id="DailyLogsDetailsModal"
          intl={intl}
          isOpen={this.state.isDailyLogsDetailsModalOpen}
          onCloseModal={() => this.setState({ isDailyLogsDetailsModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          log={dailyLogs?.find(d => d.id === this.state.currentDailyLogId)}
        />
        <SubscriptionModal
          id="SusbcriptionModal"
          isOpen={this.state.isSubscriptionModalOpen}
          onCloseModal={() => this.setState({ isSubscriptionModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          subscriptionMessageKey="TransactionPanel.subscriptionMessage"
          history={history}
          location={locationObject}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,
  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,
  params: shape({
    id: string.isRequired,
    tab: string.isRequired,
  }).isRequired,

  // Sale related props
  onMarkCompleted: func.isRequired,
  markCompletedInProgress: bool.isRequired,
  markCmopletedError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
