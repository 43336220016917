import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, FieldSelect, FieldDropzone } from '../../components';
import { required, nonEmptyArray } from '../../util/validators';

import css from './DailyLogsForm.module.css';

const issuesOptions = [
  { key: 'no', label: 'No' },
  { key: 'yes', label: 'Yes' },
];

const DailyLogsFormComponent = props => {
  const [files, setFiles] = useState([]);
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          values,
          form,
          invalid,
          handleSubmit,
          intl,
          createLogError,
          createLogInProgress,
          dailyLogSubmitted,
        } = fieldRenderProps;

        const notesMessage = intl.formatMessage({ id: 'DailyLogsForm.notes' });
        const notesPlaceholderMessage = intl.formatMessage({
          id: 'DailyLogsForm.notesPlaceholder',
        });

        const jobProgressMessage = intl.formatMessage({ id: 'DailyLogsForm.jobProgress' });
        const jobProgressPlaceholderMessage = intl.formatMessage({
          id: 'DailyLogsForm.jobProgressPlaceholder',
        });

        const dateMessage = intl.formatMessage({ id: 'DailyLogsForm.date' });
        const dateRequiredMessage = intl.formatMessage({
          id: 'DailyLogsForm.dateRequired',
        });

        const issuesMessage = intl.formatMessage({ id: 'DailyLogsForm.issues' });
        const issuesPlaceholderMessage = intl.formatMessage({
          id: 'DailyLogsForm.issuesPlaceholder',
        });
        const issuesRequiredMessage = intl.formatMessage({ id: 'DailyLogsForm.issuesRequired' });

        const issueReasonMessage = intl.formatMessage({ id: 'DailyLogsForm.issueReason' });
        const issueReasonPlaceholderMessage = intl.formatMessage({
          id: 'DailyLogsForm.issueReasonPlaceholder',
        });
        const issueReasonRequired = intl.formatMessage({
          id: 'DailyLogsForm.issueReasonRequired',
        });

        const imagesLabel = intl.formatMessage({
          id: 'DailyLogsForm.imagesLabel',
        });

        const errorMessage = (
          <p className={css.error}>
            <FormattedMessage id="DailyLogsForm.logSubmitFailed" />
          </p>
        );
        const errorArea = createLogError ? errorMessage : null;

        const logSubmitMessage = intl.formatMessage({
          id: 'DailyLogsForm.logSubmit',
        });

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = createLogInProgress;
        const imagesDisabled = createLogInProgress;
        const submitDisabled = invalid || submitInProgress;

        return (
          <Form
            className={classes}
            onSubmit={async values => {
              // As handleSubmit is asynchronous function
              // we can await until it finishes and clear
              // the uplaoded files
              await handleSubmit(values, form);
              setFiles([]);
            }}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            <FieldTextInput
              className={css.field}
              type="textarea"
              id={formId ? `${formId}.notes` : 'notes'}
              name="notes"
              label={notesMessage}
              placeholder={notesPlaceholderMessage}
            />

            <FieldTextInput
              className={css.field}
              type="textarea"
              id={formId ? `${formId}.jobProgress` : 'jobPorgress'}
              name="jobProgress"
              label={jobProgressMessage}
              placeholder={jobProgressPlaceholderMessage}
            />

            <FieldTextInput
              className={css.field}
              type="date"
              id={formId ? `${formId}.date` : 'date'}
              name="date"
              label={dateMessage}
              validate={required(dateRequiredMessage)}
            />

            <FieldSelect
              className={css.field}
              id={formId ? `${formId}.issues` : 'issues'}
              name="issues"
              label={issuesMessage}
              validate={required(issuesRequiredMessage)}
            >
              <option disabled value="">
                {issuesPlaceholderMessage}
              </option>
              {issuesOptions.map(p => {
                return (
                  <option key={p.key} value={p.key}>
                    {p.label}
                  </option>
                );
              })}
            </FieldSelect>

            {values.issues === 'yes' ? (
              <FieldTextInput
                className={css.field}
                type="textarea"
                id={formId ? `${formId}.issueReason` : 'issueReason'}
                name="issueReason"
                label={issueReasonMessage}
                placeholder={issueReasonPlaceholderMessage}
                validate={required(issueReasonRequired)}
              />
            ) : null}

            <FieldDropzone
              id="attachments"
              name="attachments"
              label={imagesLabel}
              disabled={imagesDisabled}
              files={files}
              setFiles={setFiles}
            />

            {errorArea}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              ready={dailyLogSubmitted}
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              {logSubmitMessage}
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

DailyLogsFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  createLogError: null,
  dailyLogSubmitted: false,
};

const { bool, func, string } = PropTypes;

DailyLogsFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  createLogError: propTypes.error,
  createLogInProgress: bool.isRequired,
  dailyLogSubmitted: bool.isRequired,
};

const DailyLogsForm = compose(injectIntl)(DailyLogsFormComponent);
DailyLogsForm.displayName = 'DailyLogsForm';

export default DailyLogsForm;
